<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Events </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pt-4>
      <v-flex xs4 text-left>
        <span class="itemValue">Event Type</span>
      </v-flex>
      <v-flex xs4 text-left>
        <span class="itemValue">Hive Tag ID</span>
      </v-flex>
      <v-flex xs4 text-left>
        <span class="itemValue">Date</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start v-for="(item, i) in storage" :key="i">
      <v-flex xs4 text-left>
        <span class="itemKey">{{ item.name }}</span>
      </v-flex>
      <v-flex xs4 text-left>
        <span class="itemKey">{{ item.colony.tagid }}</span>
      </v-flex>
      <v-flex xs4 text-left>
        <span class="itemKey">
          {{ formatDate(item.eventdate) }}
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 pr-4 text-right align-self-center>
        <router-link to="/Events">
          <span
            style="font-family: poppinsregular; font-size: 13px; color: #0073CF"
          >
            View More
          </span>
          <span class="itemArrow" style="color: #0073CF">>></span>
        </router-link>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {};
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>