<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Hives </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pt-4>
      <v-flex xs3 text-left>
        <span class="itemValue">Hive ID</span>
      </v-flex>
      <v-flex xs3 text-left>
        <span class="itemValue">Source</span>
      </v-flex>
      <v-flex xs3 text-left>
        <span class="itemValue">Has Queen</span>
      </v-flex>
      <v-flex xs3 text-left>
        <span class="itemValue">Honey Type</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start v-for="(item, i) in storage" :key="i">
      <v-flex xs3 text-left>
        <span class="itemKey">{{ item.colonyidentifier }}</span>
      </v-flex>
      <v-flex xs3 text-left>
        <span class="itemKey">
          {{ item.source }}
          <span v-if="!item.source">-</span>
        </span>
      </v-flex>
      <v-flex xs3 text-left>
        <span class="itemKey">
          <span v-if="item.hasqueen">Yes</span>
          <span v-else>No</span>
        </span>
      </v-flex>
      <v-flex xs3 text-left>
        <span class="itemKey">
          {{ item.honeytype }}
          <span v-if="!item.honeytype">-</span>
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 pr-4 text-right>
        <router-link to="/Hives">
          <span
            style="font-family: poppinsregular; font-size: 13px; color: #0073CF"
          >
            View More
          </span>
          <span class="itemArrow" style="color: #0073CF">>></span>
        </router-link>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {};
  },
};
</script>