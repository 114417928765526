<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Dashboard </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12>
        <Analytics v-bind:storage="dashboard" />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex py-2>
        <v-divider></v-divider>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start py-4>
      <v-flex xs12 md6 pl-md-4 px-xl-4 pt-4 pt-md-0>
        <v-card elevation="6" min-height="37vh">
          <v-layout wrap justify-center>
            <v-flex xs12 pa-2>
              <Events v-bind:storage="dashboard.lasttenevents" />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 md6 pr-md-4 pr-xl-0 pt-4 pt-xl-0 pl-xl-4>
        <v-card elevation="6" min-height="37vh">
          <v-layout wrap justify-center>
            <v-flex xs12 pa-2>
              <Hives v-bind:storage="dashboard.lasttencolonies" />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import Analytics from "./analytics";
import Events from "./events";
import Hives from "./hives";
export default {
  components: {
    Analytics,
    Events,
    Hives,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dashboard: {},
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/userdashboard",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.dashboard = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>